import { ExportForm } from '@/classes/ExportForm'
import { Colors } from '@/classes/colors/Colors'
import StatsService from '@/services/stats.service'
import { GlobalControl } from '@/classes/stats/GlobalControl.js'
import StatsTitle from '@/components/Stats/StatsTitle/StatsTitle.vue'
import VueApexCharts from 'vue-apexcharts'
import getEnv from '@/util/env'
import moment from 'moment'
import ShareButton from '@/components/Stats/ShareButton/ShareButton.vue'
import ShareModal from '@/components/Stats/ShareModal/ShareModal.vue'
import ConfirmCustomFieldsModal from '@/components/Stats/ConfirmCustomFieldsModal/ConfirmCustomFieldsModal.vue'
import EventBus from '@/util/EventBus'
import getSymbolFromCurrency from 'currency-symbol-map'
import { ChannelSms } from '@/models/ChannelSms'
import Campaign from '@/models/Campaign'
import StatsMixin from '../../../../Stats/Mixins/StatsMixin'
import StatCard from '@/components/Stats/Card/StatCard.vue'
import Pie from '@/components/Stats/Pie/Pie.vue'
import RadialBar from '@/components/Stats/RadialBar/RadialBar.vue'
import RadialBarWithLegend from '@/components/Stats/RadialBar/RadialBarWithLegend.vue'
import SimpleBar from '@/components/Stats/SimpleBar/SimpleBar.vue'
import StackedColumn from '@/components/Stats/StackedColumn/StackedColumn.vue'
import StackedMultiple from '@/components/Stats/StackedColumn/StackedMultiple.vue'
import InteractionBar from '@/components/Stats/InteractionBar/InteractionBar.vue'
import { SmsCalculatedData } from '@/classes/stats/Calculations/Sms/SmsCalculatedData'
import { SmsGraphicData } from '@/classes/stats/SmsGraphicData'
import DownloadObject from '@/util/DownloadObject'

export default {
  name: 'CampaignSmsStats',
  components: { StatsTitle, apexchart: VueApexCharts, ShareButton, ShareModal, ConfirmCustomFieldsModal, StatCard, RadialBar, RadialBarWithLegend, SimpleBar, StackedColumn, InteractionBar, StackedMultiple, Pie },
  mixins: [StatsMixin],
  data: function () {
    return {
      globalControl: new GlobalControl(),
      user: JSON.parse(localStorage.getItem('user')),
      loading: true,
      options: {},
      statuses: [],
      content: [],
      searchTerm: '',
      itemsPerPage: 5,
      pageCount: 0,
      page: 1,
      totalSendings: 0,
      currencySymbol: '',
      additional: undefined,
      data: [],
      debouncedInput: '',
      timeout: null,
      exportForm: new ExportForm(),
      chartData: [],
      chartOptions: {
        legend: {
          show: false,
        },
        fill: {
          colors: [Colors.colorChartDelivered, Colors.colorChartUndelivered, Colors.colorChartOpened],
        },
        labels: [this.$t('Entregados').toString(), this.$t('No Entregados').toString(), this.$t('Pendientes').toString()],
        responsive: [{
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              type: 'donut',
            },
          },
        }],
      },
      chartDataInfo: [],
      deliveredPercentage: 0,
      unsubscribedPercentage: 0,
      unsubscribedTotal: 0,
      series: [],
      ready: true,
      campaignId: null,
      campaign: new Campaign(),
      channel: new ChannelSms(),
      tags: null,
      arrStatuses: Colors.statusesColors,
      barChartOptions: {
        title: {
          text: this.$t('Interaciones de usuario'),
        },
        series: [
          {
            name: this.$t('Entregados'),
            data: [],
          },
          {
            name: this.$t('Clics'),
            data: [],
          },
          {
            name: this.$t('Bajas'),
            data: [],
          },
        ],
        chart: {
          type: 'bar',
        },
        plotOptions: {
          bar: {
            dataLabels: {
              position: 'top',
            },
          },
        },
        tooltip: {
          shared: true,
          intersect: false,
        },
        colors: [Colors.colorChartDelivered, Colors.colorChartUniqueClicks, Colors.colorChartUnsubscribedLanding],
        xaxis: {
          categories: [],
        },
      },
      selectedHeaders: [],
      sendingDetails: {
        initDate: '',
        endDate: moment().format('YYYY-MM-DD'),
        customFields: false,
        events: [],
        columns: [],
        sendingId: null,
        campaignType: null,
      },
      readyStats: false,
      campaginDataSource: {},
      smsCalculatedData: new SmsCalculatedData(),
      cards: [],
      deliveredRatioGraphic: null,
      clicksRatioGraphicData: null,
      interactionTotal: null,
      interactionSmsLandingPage: null,
      deliveredGraphicData: null,
      redGraphicData: null,
      unsubscribedRatioBarChart: null,
      reBouncedRatioGraphicData: null,
      clickThroughRatioGraphicData: null,
      clickToOpenRatioGraphicData: null,
      deliveredRatioGraphicSmall: null,
      openedRatioGraphicData: null,
      interactionItems: null,
    }
  },
  methods: {

    setGraphicsData () {
      const smsGraphicData = new SmsGraphicData(this.smsCalculatedData, this)
      smsGraphicData.setInteractionItems(this.interactionItems)
      this.cards = smsGraphicData.getCardsData()
      this.deliveredRatioGraphic = smsGraphicData.getDeliveredRatioGraphicGraphicData()
      this.deliveredRatioGraphicSmall = smsGraphicData.getDeliveredRatioGraphicGraphicDataSmall()
      this.clicksRatioGraphicData = smsGraphicData.getClicksRatioGraphicData()
      this.unsubscribedRatioBarChart = smsGraphicData.getUnsubscribedRatioBarChartGraphicData()
      this.reBouncedRatioGraphicData = smsGraphicData.getReBouncedRatioGraphicData()
      this.redGraphicData = smsGraphicData.getRedGraphicData()
      this.deliveredGraphicData = smsGraphicData.getDeliveredGraphicData()
      this.interactionSmsLandingPage = smsGraphicData.getInteractionSmsLandingPageGraphicData()
      this.clickThroughRatioGraphicData = smsGraphicData.getClickThroughRatioGraphicData()
      this.clickToOpenRatioGraphicData = smsGraphicData.getClickToOpenRatioGraphicData()
      this.openedRatioGraphicData = smsGraphicData.getOpenedRatioGraphicData()
      this.interactionTotal = smsGraphicData.getInteractionTotalGraphicData()
    },

    sumReduceByKey (items, key) {
      return items.reduce(
        function (a, b) {
          return a + parseInt(b[key])
        }, 0)
    },

    sumReduceDecimalByKey (items, key) {
      return items.reduce(
        function (a, b) {
          return a + parseFloat(b[key])
        }, 0).toFixed(3)
    },

    calculateUndeliveredTotal (data) {
      let fixedUndeliveredTotal = parseInt(data.undelivered_total)
      if (fixedUndeliveredTotal < 0) {
        fixedUndeliveredTotal = 0
      }
      return fixedUndeliveredTotal
    },

    calculatePending (data) {
      let pending = parseInt(data.sent_total) - parseInt(data.delivered_total) - parseInt(data.undelivered_total) - parseInt(data.rejected_total) - parseInt(data.expired_total)
      if (pending < 0) {
        pending = 0
      }
      return pending
    },

    setPieChart (totals) {
      totals.delivered_total = parseInt(totals.delivered_total)
      totals.undelivered_total = this.calculateUndeliveredTotal(totals)
      totals.pending = this.calculatePending(totals)
      this.series = [totals.delivered_total, totals.undelivered_total, totals.pending]
      this.chartDataInfo = [
        {
          label: this.$t('Enviados').toString(),
          value: totals.sent_total,
        },
        {
          label: this.$t('Entregados').toString(),
          value: totals.delivered_total,
        },
        {
          label: this.$t('No entregados').toString(),
          value: totals.undelivered_total,
        },
        {
          label: this.$t('Pendientes').toString(),
          value: totals.pending,
        },
      ]

      this.deliveredPercentage = totals.delivered_total === 0 ? totals.delivered_total : ((totals.delivered_total / totals.sent_total) * 100).toFixed(2)
      const unsubscribedPercentage = (totals.unsubscribed_total / totals.delivered_total) * 100
      this.unsubscribedPercentage = unsubscribedPercentage > 0 ? unsubscribedPercentage.toFixed(2) : '0'
      this.unsubscribedTotal = parseInt(totals.unsubscribed_total)
      this.resetReady()
    },

    resetReady () {
      this.ready = false
      setTimeout(() => {
        this.ready = true
      })
    },

    setTableData (data) {
      this.barChartOptions.series[0].data = []
      this.barChartOptions.series[1].data = []
      this.barChartOptions.series[2].data = []
      this.barChartOptions.xaxis.categories = []
      data.forEach(data => {
        data.undelivered_total = this.calculateUndeliveredTotal(data)
        data.pending = this.calculatePending(data)
        data.cost = data.cost + ' ' + this.currencySymbol
        data.total = data.total + ' ' + this.currencySymbol
        this.barChartOptions.series[0].data.push(data.delivered_total)
        this.barChartOptions.series[1].data.push(data.clicked_total)
        this.barChartOptions.series[2].data.push(data.unsubscribed_unique)
        this.barChartOptions.xaxis.categories.push(this.campaign.campaignName + '- Envío ' + data.sending_id)
      })
      this.content = data
    },

    getDataFromApi (params = {}) {
      EventBus.$emit('showLoading', true)
      this.content = []
      this.loading = true
      this.readyStats = false
      params = this.getParams()
      StatsService.getShareSmsCampaignDatatable(params, this.$route.params.shareHash)
        .then(
          (response) => {
            this.campaginDataSource = response
            this.additional = JSON.parse(JSON.stringify(response.additional))
            this.data = JSON.parse(JSON.stringify(response.data))
            this.setTableData(response.data)
            this.itemsPerPage = parseInt(response.per_page)
            this.page = response.current_page
            this.totalSendings = response.total
            this.setPieChart(this.additional[0])
            this.sendingDetails.initDate = moment(this.data[0].sending_date).format('YYYY-MM-DD')
            this.sendingDetails.sendingId = this.data[0].sending_id
            this.sendingDetails.campaignType = this.data[0].campaign_type_name
            setTimeout(() => {
              this.smsCalculatedData.calculate(this.campaginDataSource)
              this.setGraphicsData()
              this.readyStats = true
            }, 1000)
          },
          () => {},
        )
        .finally(() => {
          this.loading = false
          EventBus.$emit('showLoading', false)
        })
    },

    getParams () {
      const params = {
        page: this.options.page,
        perPage: this.options.itemsPerPage,
        searchTerm: this.searchTerm,
        campaignId: this.campaign.campaignId || this.campaignId,
        shareHash: this.$route.params.shareHash,
      }

      for (let i = 0; i < this.options.sortBy.length; i++) {
        params['sortBy[' + i + '][field]'] = this.options.sortBy[i]
        params['sortBy[' + i + '][dir]'] = this.options.sortDesc[i]
          ? 'asc'
          : 'desc'
      }
      return params
    },

    getColumns () {
      const columns = []
      this.selectedHeaders.forEach(function (column) {
        columns.push(column.value)
      })
      return columns
    },

    exportCsv () {
      this.exportAction('csv')
    },

    exportPdf () {
      this.exportAction('pdf')
    },

    exportAction (urlSuffix) {
      const url = getEnv('VUE_APP_API_URL') + 'api/share/stats/sms/campaign-export-' + urlSuffix + '?' + this.getUrlParams()
      window.location.href = url
    },

    getUrlParams () {
      return new URLSearchParams(this.setFromParamsAndColumns(this.getParams(), this.getColumns())).toString()
    },

    setFromParamsAndColumns (params, columns) {
      const data = {}
      data.campaignId = this.campaign.campaignId
      data.columns = JSON.stringify(columns)
      data.searchTerm = params.searchTerm
      data.sort = params.sortBy ? params.sortBy : ''
      data.shareHash = this.$route.params.shareHash
      return data
    },

    selectedColumn (column) {
      let exist = false
      for (let i = 0; i < this.selectedHeaders.length; i = i + 1) {
        if (!exist) {
          exist = this.selectedHeaders[i].value === column
        }
      }
      return exist
    },

    showConfirmCustomFields (actionMode) {
      EventBus.$emit('showConfirmCustomFields', actionMode)
    },
    statusColor (campaign) {
      return this.arrStatuses[campaign.guid]?.color
    },

    showButtonInteractionItem (item) {
      if (item.typeId === 'UNSUB_URL' || (item.typeId !== 'UNSUB_URL' && item.id)) {
        return true
      }

      return false
    },
    clickInteractionItem (item) {
      switch (item.typeId) {
        case 'UNSUB_URL':
          this.$router.push('/tools/unsubscribe/editor')
          break
        case 'LAND_URL':
          this.$router.push(`/template/landing/create?path=landings/${ item.data.filename }`)
          break
        case 'FORM':
          this.$router.push(`/tools/forms/editor?id=${ item.id }`)
          break
        case 'ATTACHMENT':
          DownloadObject.download(item.data.url + '&token=' + this.user.token, item.data.name)
          break
        case 'URL':
          return this.$router.push(`/tools/user/urls?edit_id=${ item.id }`)
      }
    },
  },
  computed: {
    interactionHeaders () {
      return [
        {
          text: this.$t('Tipo de interacción'),
          value: 'type',
          width: '60%',
        },
        // {
        //   text: this.$t('Ver'),
        //   value: 'id',
        // },
        {
          text: this.$t('Clics'),
          value: 'clicks',
        },
        // {
        //   text: this.$t('Acciones'),
        //   value: 'actions',
        // },
      ]
    },
    headers: function () {
      return [
        {
          text: this.$t('ID Envío'),
          value: 'sending_id',
        },
        {
          text: this.$t('Fecha'),
          value: 'sending_date',
        },
        {
          name: 'tags',
          text: this.$t('Tags'),
          value: 'tags',
        },
        {
          text: this.$t('Enviados'),
          value: 'sent_total',
        },
        {
          text: this.$t('Entregados'),
          value: 'delivered_total',
        },
        {
          text: this.$t('Entregados únicos'),
          value: 'delivered_unique',
        },
        {
          text: this.$t('Rechazados'),
          value: 'rejected_total',
        },
        {
          text: this.$t('No entregados'),
          value: 'undelivered_total',
        },
        {
          text: this.$t('Caducados'),
          value: 'expired_total',
        },
        {
          text: this.$t('Pendiente'),
          value: 'pending',
        },
        {
          text: this.$t('Clics'),
          value: 'clicked_total',
        },
        {
          text: this.$t('Bajas'),
          value: 'unsubscribed_unique',
        },
        {
          text: this.$t('Coste'),
          align: 'start',
          value: 'cost',
          class: 'w-6',
        },
      ]
    },
    showHeaders () {
      return this.headers.filter(s => this.selectedHeaders.includes(s))
    },
    params (nv) {
      return {
        ...this.options,
        query: this.searchTerm,
      }
    },
    searchTermHelper: {
      get: function () {
        return this.debouncedInput
      },
      set: function (val) {
        this.debouncedInput = val
        if (this.timeout !== null) {
          clearTimeout(this.timeout)
        }
        this.timeout = setTimeout(() => {
          this.searchTerm = val
        }, 700)
      },
    },
    locale () {
      return this.$i18n.locale
    },
  },
  watch: {
    params: {
      handler () {
        if (this.ready) {
          this.getDataFromApi()
        }
      },
      deep: true,
    },
    locale () {
      this.setGraphicsData()
    },
  },

  created () {
    this.selectedHeaders = this.headers
    this.campaignId = this.$route.params.id

    StatsService.getShareSmsCampaignData(this.campaignId, this.$route.params.shareHash)
      .then(
        (response) => {
          this.campaign = response.campaign
          this.channel = response.channel
          this.tags = response.tags
          this.currencySymbol = getSymbolFromCurrency(response.currencyCode)
          this.interactionItems = response.clickStats
        },
        () => {},
      )
      .finally(() => {
        this.loading = false
      })
  },
}
