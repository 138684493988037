import { Sending } from './Sending'
import moment from 'moment'

export default class Campaign {
  id
  name
  sendings = []
  recipientsSource
  type
  subtype
  campaignGroups
  groups
  countryIso
  costs = { sendingCost: 0, skipValidateBalance: false }
  optOut
  groupsLimit
  allowDuplicates

  constructor (channel = null) {
    if (!channel) { return }
    this.id = null
    this.name = ''
    this.sendings = [new Sending(channel)]
    this.recipientsSource = 'import'
    this.type = null
    this.subtype = ''
    this.campaignGroups = []
    this.groups = []
    this.countryIso = ''
    this.costs = { sendingCost: 0, skipValidateBalance: false }
    this.optOut = ''
    this.groupsLimit = null
    this.allowDuplicates = false
  }

  load (campaign) {
    this.id = campaign.id
    this.name = campaign.name
    this.type = campaign.campaign_type_id
    this.subtype = campaign.campaign_subtype
    this.countryIso = campaign.countryIso
    this.recipientsSource = campaign.recipients_source ? campaign.recipients_source : this.recipientsSource
    this.optOut = campaign.optOut?.length ? (' ' + campaign.optOut) : ''
    this.groupsLimit = campaign.groupsLimit
    this.campaignGroups = campaign.campaign_groups
    this.allowDuplicates = campaign.allow_duplicates
    this.setGroupsFromCampaign(campaign)
    this.setSendingsFromCampaign(campaign)

    if (this.recipientsSource === 'import' && this.sendings[0]?.file?.original_filename === 'contacts') {
      this.recipientsSource = 'import-copy-paste'
    }
  }

  setSendingsFromCampaign (campaign) {
    if (campaign.sendings === undefined || campaign.sendings.length === 0) {
      return
    }

    this.sendings = []
    console.log('campaign.sendings: ', campaign.sendings)
    for (let i = 0; i < campaign.sendings.length; i++) {
      this.sendings[i] = new Sending()
      this.sendings[i].load(campaign.sendings[i])
    }
  }

  setGroupsFromCampaign (campaign) {
    if (campaign.groups === undefined || campaign.groups.length === 0) {
      return
    }

    this.groups = []
    for (let i = 0; i < campaign.groups.length; i++) {
      const group = campaign.groups[i]
      this.groups.push(Number(String(group.id)))
    }
  }

  // = Array<{ code: number, label: string }>
  setGroups (groups) {
    this.groups = []
    if (groups) {
      groups.forEach(group => {
        this.groups.push(group)
      })
    }
  }

  formatData () {
    const data = JSON.parse(JSON.stringify(this))
    if (data.sendings[0].sendingDate != null) {
      data.sendings[0].sendingDate = moment(this.sendings[0].sendingDate).format('YYYY-MM-DD HH:mm:ss')
    }

    if (data.sendings[0].expirationDate != null) {
      data.sendings[0].expirationDate = moment(this.sendings[0].expirationDate).format('YYYY-MM-DD HH:mm:ss')
    }
    return data
  }

  messageHasLanding () {
    return this.sendings[0].channel.message && this.sendings[0].channel.message.includes('{LAND_URL}')
  }
}
