import { ChannelSms } from './ChannelSms'
import { ChannelMailing } from './ChannelMailing'
import { ChannelVoice } from './ChannelVoice'
import { ChannelType } from './ChannelType'
import { CsvFileConfig } from './CsvFileConfig'
import { AutomaticConfig } from './AutomaticConfig'
import { SendingStatuses } from './SendingStatuses'

export class Sending {
  id = null
  statusId = SendingStatuses.SAVED
  sendingDate = null
  expirationDate = null
  sendingTimezone = null
  batchParts = null
  batchMinutes = null
  tags = []
  channel
  automaticConfig = new AutomaticConfig()
  csvFileConfig = new CsvFileConfig()
  totalRecipients = 0
  recipientsTest = []
  shippingMode = 'now'
  file = null

  constructor (channel) {
    this.channel = channel
  }

  load (sending) {
    this.id = sending.id ? parseInt(sending.id) : null
    this.statusId = sending.status_id ? parseInt(sending.status_id) : SendingStatuses.SAVED
    this.sendingDate = sending.sending_date
    this.expirationDate = sending.expiration_date
    this.sendingTimezone = sending.sending_timezone ? sending.sending_timezone : ''
    this.batchParts = sending.batch_parts
    this.batchMinutes = sending.batch_minutes
    this.csvFileConfig = sending.file ? new CsvFileConfig(sending.file) : new CsvFileConfig()
    this.automaticConfig = sending.automatic ? new AutomaticConfig(sending.automatic) : new AutomaticConfig()
    this.totalRecipients = sending.total_recipients
    this.recipientsTest = []
    this.shippingMode = sending.shipping_mode
    this.file = sending.file
    this.formatTags(sending)
    this.assignCorrectChannel(sending)
  }

  assignCorrectChannel (sending) {
    // @ts-ignore
    switch (parseInt(sending.channel_type_id)) {
      case ChannelType.SMS_ID:
        this.channel = new ChannelSms(sending.channel_sms)
        break
      case ChannelType.MAILING_ID:
        this.channel = new ChannelMailing(sending.channel_email)
        break
      case ChannelType.VOICE_INTERACTIVE_ID:
        this.channel = new ChannelVoice()
        this.channel.setData(sending.channel_voice)
        break
    }
  }

  formatTags (sending) {
    for (let i = 0; i < sending.tags.length; i++) {
      const tag = sending.tags[i]
      this.tags.push(tag.tag)
    }
  }

  // Array<{ text: string }>
  uiTags () {
    return this.tags.map(tag => {
      return { text: tag }
    })
  }

  // Array<{text:string}>
  setUiTags (tags) {
    this.tags = []
    tags.forEach(tag => {
      this.tags.push(tag.text)
    })
  }
}
